import { alert } from '~/utils/alertModals';
import cartIconQuantity from '~/state/CartIconQuantity';
import guestCheckoutConfirmed from '~/state/guestCheckoutConfirmed';
import { getResponseErrorsAsHtml } from '~/utils/helpers';
import { CartItem, CartSummary } from '~/types/Cart';

const state = reactive<{
    items: CartItem[];
    summary: CartSummary | null;
    loadingContent: boolean;
}>({
    items: [],
    summary: null,
    loadingContent: false,
});

const loadContentRequest = ref<Promise<void> | null>(null);

const hasOutOfStockProduct = computed(() => {
    return state.items.filter((cartItem) => cartItem.isOutOfStock).length > 0;
});

const hasPriceChange = computed(() => {
    return state.items.filter((cartItem) => cartItem.hasPriceChange).length > 0;
});

const isEmpty = computed(() => {
    return state.items.length === 0;
});

const loadContent = async (
    providedArgs: { force?: boolean; updatePreviousPrices?: boolean } = {},
) => {
    const args = {
        updatePreviousPrices: true,
        force: false,
        ...providedArgs,
    };

    if (!loadContentRequest.value || args.force) {
        state.loadingContent = true;

        loadContentRequest.value = useAuth()
            .apiRequest('GET', 'cart/content', {
                updatePreviousPrices: args.updatePreviousPrices,
            })
            .then((responseData: any) => {
                state.items = responseData.data.items;
                state.summary = responseData.data.summary;
                guestCheckoutConfirmed.value =
                    responseData.data.guestCheckoutConfirmed;
                cartIconQuantity.value = responseData.data.itemsCount;
            })
            .catch((error) => {
                alert(
                    'Error loading cart items',
                    getResponseErrorsAsHtml(error.data),
                );
            })
            .finally(() => {
                state.loadingContent = false;
            });
    }

    return loadContentRequest.value;
};

export default function () {
    return {
        ...toRefs(state),
        hasOutOfStockProduct,
        hasPriceChange,
        isEmpty,
        loadContent,
    };
}
