<template>
    <div
        v-if="!loading"
        class="min-w-0 rounded-md bg-white px-6 py-5 md:drop-shadow-[0_0_3px_rgba(0,0,0,0.25)]"
    >
        <div class="flex items-center justify-items-stretch gap-3">
            <img
                v-if="logoUrl"
                class="h-12 w-12 object-contain"
                :src="logoUrl"
                alt="logo"
            />

            <div v-else class="rounded-full bg-gray-200 p-3">
                <Icon name="user-large" class="text-4xl text-gray-500" />
            </div>

            <div class="flex-1">
                <div class="text-sm">
                    <slot name="intro"></slot>
                </div>
                <div
                    class="mt-0.5 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-bold uppercase"
                >
                    {{ name }}
                </div>

                <div class="mt-0.5 text-sm">
                    <a
                        v-if="user"
                        href="#"
                        class="font-semibold"
                        @click.prevent="onSignOut"
                    >
                        Sign out
                    </a>

                    <NuxtLink v-else to="/login" class="font-semibold">
                        Log in
                    </NuxtLink>
                </div>
            </div>
            <Icon
                v-if="showClose"
                name="close"
                class="self-start text-xl"
                @click="emit('close')"
            />
        </div>
        <div v-if="slots.content" class="mt-4 border-t border-grind-200 pt-4">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
const { user, loading } = useAuth();

const emit = defineEmits(['on-sign-out', 'close']);

const slots = useSlots();

withDefaults(
    defineProps<{
        name?: string;
        logoUrl?: string;
        showClose?: boolean;
    }>(),
    {
        name: '',
        showClose: false,
    },
);

const onSignOut = async () => {
    emit('on-sign-out');

    await useAuth().logout();
    await useCartState().loadContent({ force: true });
};
</script>
